<template>
  <ul class="nav nav-tabs">
    <li v-for="option in options" :key="option" class="nav-item">
      <span style="cursor: pointer" :class="{'nav-link': true, active: option === value}" @click="$emit('input', option)" v-t="option"></span>
    </li>
  </ul>
</template>

<script>
export default {
  name: "Tabs",
  props: {
    options: Array,
    value: String
  }
}
</script>

<style scoped>

</style>
