<template>
  <div>
    <div class="fw-bold fs-5">{{ $t(option.name) }}</div>
    <div class="fw-light">
      <button @click="selectAll" type="button" class="btn btn-link btn-sm" v-t="'Atzīmēt visu'"></button>
      <button @click="selectNone" type="button" class="btn btn-link btn-sm" v-t="'Noņemt visu'"></button>
    </div>
    <div v-for="value in option.values" :key="value.mask" class="form-check">
      <label>
        <input type="checkbox" :value="value.mask" v-model="valueModel" />
        {{ $t(value.name) }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "Option",
  props: {
    option: Object,
    value: Array
  },
  computed: {
    valueModel: {
      get () { return this.value },
      set (value) { this.$emit('input', value) },
    },
  },
  methods: {
    selectAll() {
      this.$emit('input', this.option.values.map(i => i.mask))
    },
    selectNone() {
      this.$emit('input', [])
    }
  }
}
</script>

<style scoped>

</style>
