<template>
  <div>
    <div class="h2 mb-4" v-html="$t('inflections_title', {'word': `<strong class=\'text-success\'><em>${lemma}</em></strong>`})"></div>
    <component v-if="this.api_data" v-bind:is="components[api_data.pos]" :api_data="api_data" v-model="value" :show_answers="show_answers" ></component>
    <div v-if="correct_answers!=null" class="alert alert-info mt-3" role="alert"
         v-html="$t('check_result',{
           'correct': correct_answers,
           'total': Object.keys(value).length,
           'percentage': Math.round(100*correct_answers/Object.keys(value).length)
         })"
    ></div>
    <div v-if="this.api_data" class="input-group justify-content-center mt-3">
      <button class="btn btn-primary" @click="verify">{{$t('Pārbaudīt')}} <i class="bi bi-clipboard-check"></i></button>
      <button class="btn btn-outline-primary" @click="show_answers=!show_answers">{{$t('Parādīt atbildes')}} <i class="bi bi-eye"></i></button>
      <button class="btn btn-outline-primary" @click="randomLemma">{{$t('Cits')}} <i class="bi bi-shuffle"></i></button>
    </div>


  </div>
</template>

<script>

import Verb from '@/components/inflections/Verb'
import Noun from '@/components/inflections/Noun'
// import Pronoun from "@/components/inflections/Pronoun";
import Adjective from "@/components/inflections/Adjective";
// import Numeral from "@/components/inflections/Numeral";

const components = {
  "Darbības vārds": Verb,
  "Lietvārds": Noun,
  "Vietniekvārds": Noun,
  "Īpašības vārds": Adjective,
  "Skaitļa vārds": Adjective
}

export default {
  name: "Sentences",
  data() {
    return {
      lemma: "",
      api_data: null,
      value: null,
      correct_answers: null,
      show_answers: false,
      components
    }
  },
  mounted() {
    this.randomLemma()
  },
  watch: {
    '$route.params.filter': function () { this.loadData() }
  },
  methods: {
    randomLemma() {
      this.lemma = ''
      this.loadLemma()
    },
    async loadLemma() {
      this.reset()
      const response = await this.$http.post(process.env.VUE_APP_API_HOST+'/inflections', {lemma: this.lemma, filter:this.$route.params.filter});
      if(response.data.error)
        alert(response.data.error);
      else {
        this.api_data = response.data;
        this.lemma = response.data.lemma;
      }
    },
    verify() {
      if(!this.value)
        return
      const value = {...this.value}
      let correct = 0
      for(let k in value) {
        correct += value[k].is_correct = value[k].original.includes(value[k].guess)
      }
      this.correct_answers = correct
    },
    reset() {
      this.api_data = null
      this.value = null
      this.show_answers = false
      this.correct_answers = null
    }
  }

}
</script>

<style scoped>

</style>
