<template>
  <div>
    <div class="accordion mb-3">
      <Category v-for="category in structure" v-model="answers[category.key]" :key="category.key" :category="category" />
    </div>
    <button @click="conitnue" type="button" class="btn btn-primary btn-lg" v-t="'Turpināt'"></button>
  </div>
</template>

<script>
import Category from "@/components/option_menus/Category";

export default {
  name: "ParamsMenu",
  props: {
    structure: Array
  },
  data() {
    return {
      answers: {}
    }
  },
  methods: {
    conitnue() {
      const options = []
      for(let cid in this.answers) {
        if(!this.answers[cid].checked)
          continue

        const answ = []
        for(let i = 0; i < 100; i++)
          answ.push([])

        answ[0].push(cid)

        for(let oid in this.answers[cid].options)
        {
          for(let mask of this.answers[cid].options[oid])
          {
            for (let i = 0; i < mask.length; i++) {
              if(mask[i] !== '_')
                answ[i].push(mask[i])
            }
          }
        }

        let mask = ''
        for(let p in answ)
        {
          if(answ[p].length === 0)
            mask += '.'
          else if(answ[p].length === 1)
            mask += answ[p][0]
          else
            mask += '['+answ[p].join('')+']'
        }

        mask = mask.replace(/\.+$/,'')

        options.push(mask)
      }

      this.$emit('confirm', options)
      // const params = {}
      // if(options.length > 0)
      //   params.filter = options.join(',')
      // this.$router.push({ name: 'inflection', params })
    }

  },
  components: {
    Category
  }
}
</script>

<style scoped>

</style>
