<template>
  <div>
    <div class="h2" v-t="'Rakstīšana'"></div>
    <div class="h4" v-t="'Pārraksti tekstu'"></div>
    <div class="mb-3">
      <Tabs :options="['Datorraksts', 'Rokraksts']" v-model="type" />
    </div>
    <div v-if="this.ref">
      <div class="form-control text-start mb-3" style="height: 100%">
        <img v-if="img" :src="img" style="height: 40px; max-width: 100%; object-fit: contain" />
        <span v-else>{{this.ref}}</span>
      </div>
      <textarea :class="{'form-control': true, ...validationClass}" style="height: 100%" v-model="hyp" />
    </div>
    <div v-if="this.ref" class="input-group justify-content-center mt-3">
      <button class="btn btn-primary" @click="loadSentence">{{$t('Cits')}} <i class="bi bi-shuffle"></i></button>
    </div>
  </div>
</template>

<script>


import Tabs from "@/components/Tabs";
export default {
  name: "Spelling",
  components: {Tabs},
  data() {
    return {
      ref: null,
      hyp: null,
      img: null,
      type: 'Datorraksts'
    }
  },
  computed: {
    validationClass() {
      const classes = {}

      if(this.ref && this.hyp) {
        if(this.hyp === this.ref)
          classes['is-valid'] = true
        else if(this.hyp.length > this.ref.length || this.ref.substring(0, this.hyp.length) !== this.hyp)
          classes['is-invalid'] = true
      }

      return classes
    }

  },
  watch: {
    type: function () {
      console.log(this.type)
      this.loadSentence()
    }
  },
  mounted() {
    this.loadSentence()
  },
  methods: {
    async loadSentence() {
      this.reset()
      const response = await this.$http.post(process.env.VUE_APP_API_HOST+'/writing', {type: this.type});
      if(response.data.error)
        alert(response.data.error);
      else
        this.ref = response.data.ref;
        if(response.data.img)
          this.img = response.data.img;
    },
    reset() {
      this.ref = null
      this.hyp = null
      this.img = null
    }
  }

}
</script>

<style scoped>

</style>
