<template>
  <div>
    <div class="sentence">
      {{left_context}}

      <div class="input-group" style="width: auto; display: inline-flex; flex-wrap: nowrap">
        <span class="input-group-text" v-tooltip="hint.long"><em>{{word.lemma}} <sub v-if="hint">{{hint.short}}</sub></em></span>
        <input type="text" v-model="guessModel" :class="[statusClass, 'bg-opacity-25', 'input-word', 'form-control']"  />
        <span class="input-group-text" v-if="show_answers" type="text">{{word.token}}</span>
        <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i class="bi bi-question-circle"></i></button>
        <ul class="dropdown-menu dropdown-menu-end">
          <li v-for="form in forms" :key="form"><span class="dropdown-item" @click="guessModel=form">{{form}}</span></li>
        </ul>
      </div>

      {{right_context}}
    </div>
  </div>
</template>

<script>

export default {
  name: "Sentence",
  props: {
    forms: {
      type: Array,
      default: null
    },
    word: {
      type: Object,
      default: null
    },
    value: {
      type: String,
      default: null
    },
    is_correct: {
      type: Boolean,
      default: null
    },
    left_context: {
      type: String,
      default: null
    },
    right_context: {
      type: String,
      default: null
    },
    show_answers: {
      type: Boolean,
      default: true
    },
  },
  computed: {
    statusClass() {
      if(this.is_correct === true)
        return 'bg-success'
      else if(this.is_correct === false)
        return 'bg-danger'

      return ''
    },
    guessModel: {
      get () {
        return this.value
      },
      set (value) { this.$emit('input', value) },
    },
    hint() {
      let short = []
      let long = []
      if(this.word.tag[0] === 'v') {
        switch (this.word.tag[4]) {
          case 'p':
            short.push(this.$t('tag.'))
            long.push(this.$t('tagadnes'))
            break
          case 'f':
            short.push(this.$t('nāk.'))
            long.push(this.$t('nākotnes'))
            break
          case 's':
            short.push(this.$t('pag.'))
            long.push(this.$t('pagātnes'))
            break
        }

        switch (this.word.tag[8]) {
          case 's':
            short.push(this.$t('vsk.'))
            long.push(this.$t('vienskaitļa'))
            break
          case 'p':
            short.push(this.$t('dsk.'))
            long.push(this.$t('daudzskaitļa'))
            break
        }

        switch (this.word.tag[7]) {
          case '1':
            short.push(this.$t('1. pers.'))
            long.push(this.$t('1. persona'))
            break
          case '2':
            short.push(this.$t('2. pers.'))
            long.push(this.$t('2. persona'))
            break
          case '3':
            short.push(this.$t('3. pers.'))
            long.push(this.$t('3. persona'))
            break
        }
      }

      if(this.word.tag[0] === 'n') {

        switch (this.word.tag[8]) {
          case 's':
            short.push(this.$t('vsk.'))
            long.push(this.$t('vienskaitļa'))
            break
          case 'p':
            short.push(this.$t('dsk.'))
            long.push(this.$t('daudzskaitļa'))
            break
        }

        switch (this.word.tag[4]) {
          case 'n':
            short.push(this.$t('nom.'))
            long.push(this.$t('nominatīvs'))
            break
          case 'g':
            short.push(this.$t('ģen.'))
            long.push(this.$t('ģenetīvs'))
            break
          case 'd':
            short.push(this.$t('dat.'))
            long.push(this.$t('datīvs'))
            break
          case 'a':
            short.push(this.$t('akuz.'))
            long.push(this.$t('akuzatīvs'))
            break
          case 'l':
            short.push(this.$t('lok.'))
            long.push(this.$t('lokatīvs'))
            break
          case 'v':
            short.push(this.$t('vok.'))
            long.push(this.$t('vokatīvs'))
            break
        }
      }

      // if(this.word.tag[0] === 'a') {
      //   switch (this.word.tag[6]) {
      //     case 'p':
      //       short += 'pamata'
      //       long += 'pamata pakāpe'
      //       break
      //     case 'c':
      //       short += 'pārākā'
      //       long += 'pārākā pakāpe'
      //       break
      //     case 's':
      //       short += 'vispārākā'
      //       long += 'vispārākā pakāpe'
      //       break
      //   }
      // }

      short = short.join(' ')
      long = long.join(' ')

      // long = '<span class="text-nowrap">'+long+'</span>'

      return {short, long}
    }
  },

  data() {
    return {
      values: {}
    }
  }
}
</script>

<style scoped>
.input-word {
  width: 120px;
}

.dropdown-toggle::after {
  display: none;
}


.sentence {
  /*display: flex;*/
  text-align: left;
  margin: 30px 0 10px;
  /*align-content: center;*/
}
</style>
