<template>
  <div>
    <div class="btn-group" role="group" aria-label="Basic checkbox toggle button group">
      <input type="checkbox" class="btn-check" id="btncheck1" autocomplete="off">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" v-model="inflect_noun" id="flexCheckDefault">
        <label class="form-check-label" for="flexCheckDefault">
          {{$t('Locīt kopā ar lietvārdu')}}
        </label>
      </div>
    </div>

    <div class="row">
      <div class="col-md mb-4" v-for="skaitlis in counts" :key="skaitlis">
        <div class="h2 mb-2">{{$t(skaitlis)}}</div>
        <div v-for="loc in ['Nominatīvs', 'Ģenitīvs', 'Datīvs', 'Akuzatīvs', 'Lokatīvs']" :key="loc">
          <Field
              v-if="fields[loc+skaitlis]"
              :prefix="loc+' ('+questions[loc]+')'"
              v-bind="fields[loc+skaitlis]"
              :value="fields[loc+skaitlis].guess"
              @input="v => setValue(loc+skaitlis, v)"
              :show_answers="show_answers"
              :postfix="!inflect_noun ? api_data.noun[loc+skaitlis] : null"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Field from "@/components/inflections/Field";
import WordMixin from "@/components/inflections/WordMixin";
export default {
  name: "Adjective",
  components: {Field},
  mixins: [WordMixin],
  data() {
    return {
      'questions': {
        'Nominatīvs': 'kas?',
        'Ģenitīvs': 'kā?',
        'Datīvs': 'kam?',
        'Akuzatīvs': 'ko?',
        'Instrumentālis': 'ar ko?',
        'Lokatīvs': 'kur?'
      },
      'inflect_noun': true,
      'complex_fields': {}
    }
  },
  mounted() {
    this.compute_fields()
  },
  computed: {
    fields() {
      return this.complex_fields
    }
  },
  watch: {
    value() {
      this.complex_fields = this.value
    },
    inflect_noun() {
      this.compute_fields()
    },
    api_data() {
      this.compute_fields()
    }
  },
  methods: {
    compute_fields() {
      if (!this.api_data) {
        return null
      }

      if (this.value && this.inflect_noun === this.prev_inflect_noun) {
        return this.value
      }
      let model = {}
      const adj = this.api_data.main
      const noun = this.api_data.noun
      for(let k in adj) {
        model[k] = {
          'original': adj[k] + (this.inflect_noun ? ' ' + noun[k] : ''),
          'guess': this.value ? this.value[k].guess : '',
          'is_correct': null
        }
      }
      this.complex_fields = model
      this.$emit('input', model)
    }
  }
}
</script>

<style scoped>

</style>
