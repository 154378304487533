<template>
  <div>
    <div class="h2" v-t="'Vārdi teikumā'"></div>
    <ParamsMenu :structure="structure" @confirm="onConfirm" />
  </div>
</template>

<script>

import ParamsMenu from "@/components/ParamsMenu";
const structure = [
  {
    name: 'Lietvārds',
    key: 'n',
    options: [
      {
        name: 'Deklinācija',
        key: 'n',
        values: [
          {
            name: '1. deklinācija',
            mask: '_____1'
          },
          {
            name: '2. deklinācija',
            mask: '_____2'
          },
          {
            name: '3. deklinācija',
            mask: '_____3'
          },
          {
            name: '4. deklinācija',
            mask: '_____4'
          },
          {
            name: '5. deklinācija',
            mask: '_____5'
          },
          {
            name: '6. deklinācija',
            mask: '_____6'
          }
        ]
      }
    ]
  },
  {
    name: 'Darbības vārds',
    key: 'v',
    options: [
      {
        name: 'Konjugācija',
        values: [
          {
            name: '1. konjugācija',
            mask: '______1'
          },
          {
            name: '2. konjugācija',
            mask: '______2'
          },
          {
            name: '3. konjugācija',
            mask: '______3'
          },
          {
            name: 'nekārtns',
            mask: '______i'
          }
        ],
      },
      {
        name: 'Atgriezeniskums',
        values: [
          {
            name: 'tiešs',
            mask: '__n'
          },
          {
            name: 'atgriezenisks',
            mask: '__y'
          }

        ]
      }
    ]
  },
  {
    name: 'Vietniekvārds',
    key: 'pp',
    options: [
      // {
      //   name: 'Tips',
      //   values: [
      //     {
      //       name: 'Personu',
      //       mask: '_p'
      //     },
      //     {
      //       name: 'Cits',
      //       mask: '_.'
      //     }
      //   ]
      // }
    ]
  },
  {
    name: 'Īpašības vārds',
    key: 'a.....p',
    options: []
  },
  {
    name: 'Skaitļa vārds',
    key: 'm',
    options: [
      // {
      //   name: 'Tips',
      //   values: [
      //     {
      //       name: 'Pamata',
      //       mask: '_c'
      //     },
      //     {
      //       name: 'Kārtas',
      //       mask: '_o'
      //     }
      //   ]
      // }
    ]
  }
]

export default {
  name: 'SentencesMenu',
  data() {
    return {
      structure
    }
  },
  methods: {
    onConfirm(options) {
      const params = {}
      if(options.length > 0)
        params.filter = options.join(',')
      this.$router.push({ name: 'sentences', params })
    }

  },
  components: {
    ParamsMenu
  }
}
</script>
