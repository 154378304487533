<template>
  <div>
    <div class="h2" v-t="'Vārdi teikumā'"></div>
    <div class="h4" v-t="'Ievieto doto vārdu pareizajā locījumā'"></div>
    <div v-if="sentences">
      <Sentence v-for="(s, idx) in sentences" v-bind="s" :key="idx" :show_answers="show_answers" v-model="s.guess" />
    </div>
    <div v-if="correct_answers!=null" class="alert alert-info mt-3" role="alert"
         v-html="$t('check_result',{
           'correct': correct_answers,
           'total': sentences.length,
           'percentage': Math.round(100*correct_answers/sentences.length)
         })"
    ></div>
    <div class="input-group justify-content-center mt-3">
      <button class="btn btn-primary" @click="validateAnswers">{{$t('Pārbaudīt')}} <i class="bi bi-clipboard-check"></i></button>
      <button class="btn btn-outline-primary" @click="show_answers=!show_answers">{{$t('Parādīt atbildes')}} <i class="bi bi-eye"></i></button>
      <button class="btn btn-outline-primary" @click="loadData">{{$t('Citi teikumi')}} <i class="bi bi-shuffle"></i></button>
    </div>
  </div>
</template>

<script>

import Sentence from "@/components/sentences/Sentence";

export default {
  name: "Inflections",
  data() {
    return {
      show_answers: false,
      sentences: null,
      correct_answers: null
    }
  },
  components: {
    Sentence
  },
  mounted() {
    this.loadData()
  },
  watch: {
    '$route.params.filter': function () { this.loadData() }
  },
  methods: {
    async loadData() {
      this.show_answers = false
      this.correct_answers = null
      this.sentences = null
      const response = await this.$http.post(process.env.VUE_APP_API_HOST+'/sentences', {tag: this.$route.params.filter});
      this.sentences = response.data['sentences'].map((s) => { return  {...s, guess: '', is_correct: null }});

    },
    validateAnswers() {
      let correct = 0
      for(let s of this.sentences) {
        correct += s.is_correct = s.guess === s.word.token
      }
      this.correct_answers = correct

    }
  }

}
</script>

<style scoped>
.input-word {
  padding: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-help {
  padding: 4px;
  display: inline-block;
  cursor: pointer;
  background: #aaa;
  border-radius: 0 .25rem .25rem 0;
}
</style>
