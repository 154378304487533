<template>
  <div>
    <div v-for="laiks in ['Tagadne', 'Pagātne', 'Nākotne']" :key="laiks">
      <div class="h4 mt-4 mb-2" v-t="laiks"></div>
      <div class="row">
        <div v-for="skaitlis in counts" :key="skaitlis" class="col-md-6">
          <div v-for="persona in [1,2]" :key="persona" class="mb-2">
            <Field
                :prefix="pronouns[skaitlis][persona]"
                v-if="fields[skaitlis+persona+laiks]"
                v-bind="fields[skaitlis+persona+laiks]"
                :value="fields[skaitlis+persona+laiks].guess"
                @input="v => setValue(skaitlis+persona+laiks, v)"
                :show_answers="show_answers"
            />
          </div>
        </div>
        <div class="mb-2">
          <Field
              prefix="viņš, viņa, viņi, viņas"
              v-if="fields[3+laiks]"
              v-bind="fields[3+laiks]"
              :value="fields[3+laiks].guess"
              @input="v => setValue(3+laiks, v)"
              :show_answers="show_answers"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Field from "@/components/inflections/Field";
import WordMixin from "@/components/inflections/WordMixin";
export default {
  name: "Verb",
  components: {Field},
  mixins: [WordMixin],
  data() {
    return {
      pronouns: {
        'Vienskaitlis': {
          1: 'es',
          2: 'tu',
          3: 'viņš, viņa'
        },
        'Daudzskaitlis': {
          1: 'mēs',
          2: 'jūs',
          3: 'viņi, viņas'
        }
      }
    }
  }

}
</script>

<style scoped>

</style>
