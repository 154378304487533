export default {
    props: {
        api_data: {
            type: Object,
            default: null
        },
        show_answers: {
            type: Boolean,
            default: false
        },
        value: {
            type: Object,
            default: null
        }
    },
    computed: {
        fields() {
            if (!this.api_data) {
                return null
            }

            if (this.value) {
                return this.value
            }
            const res = this.prepareForms(this.api_data.forms)
            this.$emit('input', res)
            return res
        },
        counts() {
            if(!this.fields)
                return
            const c = []

            let has_plural = false
            let has_singular = false
            let has_none = false
            for(let k in this.fields) {
                has_plural = has_plural || k.includes('Daudzskaitlis')
                has_singular = has_singular || k.includes('Vienskaitlis')
                has_none = has_none || k.includes('Nepiemīt')
            }

            if(has_singular)
                c.push('Vienskaitlis')

            if(has_plural)
                c.push('Daudzskaitlis')

            if(has_none)
                c.push('Nepiemīt')

            return c
        }
    },
    methods: {
        setValue(key, value) {
            this.$emit('input', {...this.fields, [key]: {...this.fields[key], guess: value}})
        },
        prepareForms(forms) {
            let model = {}
            for(let k in forms) {
                model[k] = {
                    'original': forms[k],
                    'guess': '',
                    'is_correct': null
                }
            }
            return model
        }
    }
}
