<template>
  <div>
    <div class="h2" v-t="'Locīšana'"></div>
    <ParamsMenu :structure="structure" @confirm="onConfirm" />
  </div>
</template>

<script>

import ParamsMenu from "@/components/ParamsMenu";
const structure = [
  {
    name: 'Lietvārds',
    key: 'n',
    options: [
      {
        name: 'Deklinācija',
        values: [
          {
            name: '1. deklinācija',
            mask: '_____1'
          },
          {
            name: '2. deklinācija',
            mask: '_____2'
          },
          {
            name: '3. deklinācija',
            mask: '_____3'
          },
          {
            name: '4. deklinācija',
            mask: '_____4'
          },
          {
            name: '5. deklinācija',
            mask: '_____5'
          },
          {
            name: '6. deklinācija',
            mask: '_____6'
          }
        ]
      }
    ]
  },
  {
    name: 'Darbības vārds',
    key: 'v',
    options: [
      {
        name: 'Konjugācija',
        values: [
          {
            name: '1. konjugācija',
            mask: '______1'
          },
          {
            name: '2. konjugācija',
            mask: '______2'
          },
          {
            name: '3. konjugācija',
            mask: '______3'
          },
          {
            name: 'nekārtns',
            mask: '______i'
          }
        ],
      },
    ]
  },
  {
    name: 'Vietniekvārds',
    key: 'p',
    options: [
      {
        name: 'Tips',
        values: [
          {
            name: 'Personu',
            mask: '_p'
          },
          {
            name: 'Cits',
            mask: '_c'
          }
        ]
      }
    ]
  },
  {
    name: 'Īpašības vārds',
    key: 'a+n',
    options: [
      {
        name: 'Kopā ar',
        values: [
          {
            name: '1. deklinācijas lietvārdu',
            mask: '_____1'
          },
          {
            name: '2. deklinācijas lietvārdu',
            mask: '_____2'
          },
          {
            name: '3. deklinācijas lietvārdu',
            mask: '_____3'
          },
          {
            name: '4. deklinācijas lietvārdu',
            mask: '_____4'
          },
          {
            name: '5. deklinācijas lietvārdu',
            mask: '_____5'
          },
          {
            name: '6. deklinācijas lietvārdu',
            mask: '_____6'
          }
        ]
      }
    ]
  },
  {
    name: 'Skaitļa vārds',
    key: 'm+n',
    options: [
      {
        name: 'Kopā ar',
        values: [
          {
            name: '1. deklinācijas lietvārdu',
            mask: '_____1'
          },
          {
            name: '2. deklinācijas lietvārdu',
            mask: '_____2'
          },
          {
            name: '3. deklinācijas lietvārdu',
            mask: '_____3'
          },
          {
            name: '4. deklinācijas lietvārdu',
            mask: '_____4'
          },
          {
            name: '5. deklinācijas lietvārdu',
            mask: '_____5'
          },
          {
            name: '6. deklinācijas lietvārdu',
            mask: '_____6'
          }
        ]
      }
    ]
  }
]

export default {
  name: 'InflectionsMenu',
  data() {
    return {
      structure
    }
  },
  methods: {
    onConfirm(options) {
      const params = {}
      if(options.length > 0)
        params.filter = options.join(',')
      this.$router.push({ name: 'inflection', params })
    }

  },
  components: {
    ParamsMenu
  }
}
</script>
