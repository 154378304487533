<template>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <label :class="['accordion-button', fields.checked ? null : 'collapsed']">
          <input type="checkbox" :checked="fields.checked" @change="e => setValue(e.target.checked)" class="d-inline-block me-2"/>
          {{ $t(category.name) }}
      </label>
    </h2>
    <div v-if="category.options.length > 0" :class="['accordion-collapse', 'collapse', fields.checked ? 'show' : null]">
      <div class="accordion-body text-start">
        <Option v-for="option in category.options" :option="option" :value="fields.options[option.name]" @input="v => setOption(option.name, v)" :key="option.name" />
      </div>
    </div>
  </div>
</template>

<script>
import Option from "@/components/option_menus/Option";
export default {
  name: "Category",
  components: {Option},
  props: {
    category: {
      required: true,
      type: Object
    },
    value: Object
  },
  data() {
    return {
      checked: false
    }
  },
  computed: {
    fields() {
      if (!this.category) {
        return null
      }

      if (this.value) {
        return this.value
      }

      const v = {
        checked: false,
        options: {}
      }

      for(let o of this.category.options)
        v.options[o.name] =  []

      this.$emit('input', v)
      return v
    },
  },
  methods: {
    setValue(value) {
      this.$emit('input', {...this.fields, checked: value})
    },
    setOption(key, value) {
      this.$emit('input', {...this.fields, options: {...this.fields.options, [key]: value}})
    },
  }

}
</script>

<style scoped>

</style>
