<template>
  <div>
    <div class="row">
      <div class="col-md mb-4" v-for="skaitlis in counts" :key="skaitlis">
        <div v-if="skaitlis!=='Nepiemīt'" class="h2 mb-2">{{$t(skaitlis)}}</div>
        <div v-for="loc in ['Nominatīvs', 'Ģenitīvs', 'Datīvs', 'Akuzatīvs', 'Lokatīvs']" :key="loc">
          <Field
              v-if="fields[loc+skaitlis]"
              :prefix="loc+' ('+questions[loc]+')'"
              v-bind="fields[loc+skaitlis]"
              :value="fields[loc+skaitlis].guess"
              @input="v => setValue(loc+skaitlis, v)"
              :show_answers="show_answers"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Field from "@/components/inflections/Field";
import WordMixin from "@/components/inflections/WordMixin";
export default {
  name: "Verb",
  components: {Field},
  mixins: [WordMixin],
  data() {
    return {
      'questions': {
        'Nominatīvs': 'kas?',
        'Ģenitīvs': 'kā?',
        'Datīvs': 'kam?',
        'Akuzatīvs': 'ko?',
        'Instrumentālis': 'ar ko?',
        'Lokatīvs': 'kur?'
      }
    }
  }
}
</script>

<style scoped>

</style>
