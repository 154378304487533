<template>
  <div class="input-group">
    <input v-if="prefix" type="text" disabled v-model="prefix" class="form-control" />
    <input type="text" v-model="guessModel" :class="[statusClass, 'bg-opacity-25', 'form-control']" />
    <input v-if="show_answers" type="text" disabled v-model="original" class="form-control" />
    <input v-else type="text" disabled class="form-control"  />
    <input v-if="postfix" type="text" disabled v-model="postfix" class="form-control" />
  </div>
</template>

<script>
export default {
  name: "Field",

  props: {
    prefix: {
      type: String,
      default: null
    },
    postfix: {
      type: String,
      default: null
    },
    original: {
      type: String,
      default: null
    },
    guess: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: null
    },
    is_correct: {
      type: Boolean,
      default: null
    },
    show_answers: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    statusClass() {
      if(this.is_correct === true)
        return 'bg-success'
      else if(this.is_correct === false)
        return 'bg-danger'

      return ''
    },
    guessModel: {
      get () {
        return this.value
      },
      set (value) { this.$emit('input', value) },
    },
  },
}
</script>

<style scoped>

</style>
